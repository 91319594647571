<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Businessunit" style="width:100vw">
  <div id="bf9b207a">
    <el-radio-group id="a7a9b839" v-model="rdg_a7a9b839">
      <el-radio-button id="b8a1b817" label="开发环境">
      </el-radio-button>
      <el-radio-button id="fb556e8c" label="测试环境">
      </el-radio-button>
      <el-radio-button id="f98f2574" label="生产环境">
      </el-radio-button>
    </el-radio-group>
    <el-select id="ad2b21f3" placeholder="全部项目" v-model="val_ad2b21f3">
      <el-option v-for="item in op_ad2b21f3" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <div id="ac3e0d55">
      <div id="aa922663">
        <div id="a6363036" daltag="manual">
          <!-- <img src="../assets/img-b847e8ff.png" id="b847e8ff"/> -->
          <div id="b847e8ff">
          </div>
        </div>
        <el-table id="acdd4fe8" :data="tbd_acdd4fe8" stripe="" border="">
          <el-table-column prop="a5db9d9f" label="作者">
          </el-table-column>
          <el-table-column prop="d5b262cc" label="单元名">
          </el-table-column>
          <el-table-column prop="d19fcd3e" label="调用层级">
          </el-table-column>
          <el-table-column prop="bc3d8549" label="依赖单元">
          </el-table-column>
          <el-table-column prop="aaa9077c" label="服务单元">
          </el-table-column>
          <el-table-column prop="a4cde0ab" label="操作">
          </el-table-column>
        </el-table>
      </div>
      <el-tabs id="a2bfef83" type="card">
        <el-tab-pane label="描述">
          <div id="a5a1de4b" daltag="manual">
            <div id="a047632e">
              {{unitInfo.name}}
            </div>
            <div id="aa1707bd">
              <div id="aedf3d03">
                <div id="b1f0c46e">
                  上线时间
                </div>
                <div id="acc0763b">
                  {{unitInfo.onlineDate}}
                </div>
                <div id="afabbdd7">
                  {{unitInfo.onlineTime}}
                </div>
              </div>
              <div id="c8251ee3">
                <div id="a1d4393a">
                  在线时长
                </div>
                <div id="a9506b35">
                  {{unitInfo.onlineDuration}}
                </div>
              </div>
              <div id="e1c4c4c1">
                <div id="af24364a">
                  7日平均调用数
                </div>
                <div id="aac5813d">
                  {{unitInfo.callCount}}
                </div>
              </div>
            </div>
            <div id="ab70c0ce">
              <div id="ad6d578a">
                <div id="a24b1238">
                  重启次数
                </div>
                <div id="aecdc2ab">
                  {{unitInfo.restartCount}}
                </div>
              </div>
              <div id="a14f17c2">
                <div id="a8ec4889">
                  7日平均错误数
                </div>
                <div id="a5cf08db">
                  {{unitInfo.errorCount}}
                </div>
              </div>
              <div id="cc68871c">
                <div id="aff43803">
                  7日平均警告数
                </div>
                <div id="ab9c6ae2">
                  {{unitInfo.warnCount}}
                </div>
              </div>
            </div>
            <div id="abab9c00">
              <div id="a626c45d">
                <div id="c05e4b0e">
                  CPU核数
                </div>
                <div id="a9b37925">
                  {{unitInfo.cpu}}
                </div>
              </div>
              <div id="a0be6c39">
                <div id="a500d403">
                  内存(MiB)
                </div>
                <div id="a9816887">
                  {{unitInfo.memory}}
                </div>
              </div>
              <div id="aa110879">
                <div id="a56fd943">
                  实例数
                </div>
                <div id="c1c3fac5">
                  {{unitInfo.instance}}
                </div>
              </div>
            </div>
            <div id="e0b12218">
              <div id="a7f44db3">
                <div id="aba11f56">
                  所属者
                </div>
                <div id="a9987c12">
                  {{unitInfo.author}}
                </div>
              </div>
              <div id="ded9b94a">
                <div id="afbb932a">
                  所属组
                </div>
                <div id="a629ed8f">
                  {{unitInfo.team}}
                </div>
              </div>
            </div>
            <el-table id="c0ff828b" :data="tbd_c0ff828b" stripe="" border="">
              <el-table-column prop="a49924ce" label="接口名称">
              </el-table-column>
              <el-table-column prop="d7a4592e" label="接口权限">
              </el-table-column>
              <el-table-column prop="a899d64f" label="接口描述">
              </el-table-column>
              <el-table-column prop="a2309759" label="参数">
              </el-table-column>
              <el-table-column prop="b7c39e67" label="示例">
              </el-table-column>
              <el-table-column prop="a4a042e0" label="复制示例" daltag="manual">
                <template slot-scope="scope">
                  <el-button @click="copyexample(scope.row)" type="text" size="small">
                    复制
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="部署">
          <div id="aa87d09c" daltag="manual">
            <div id="b6f8af84">
              {{unitInfo.name}}
            </div>
            <div id="c5acd1f4">
              <vueshowdown markdown="`vue-showdown` is <font color='red'>easy</font> to use :kissing_heart:">
              </vueshowdown>
            </div>
            <el-table id="c9a1e59f" :data="tbd_c9a1e59f" stripe="" border="">
              <el-table-column prop="a3d33c6f" label="时间">
              </el-table-column>
              <el-table-column prop="af029ae1" label="PUName">
              </el-table-column>
              <el-table-column prop="aa910a65" label="版本号">
              </el-table-column>
              <el-table-column prop="a3201667" label="启动时间">
              </el-table-column>
              <el-table-column prop="c2f5ecb5" label="结束时间">
              </el-table-column>
              <el-table-column prop="de00fca6" label="部署耗时">
              </el-table-column>
              <el-table-column prop="a43a6b86" label="当前状态">
              </el-table-column>
              <el-table-column prop="f22696ca" label="部署日志内容">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="日志">
          <div id="abcc459a" daltag="manual">
            <div id="aca35975">
              {{unitInfo.name}}
            </div>
            <div id="a47ca6d9">
              <el-date-picker id="bc1c3e3a" v-model="drp_bc1c3e3a" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="结束日期" end-placeholder="开始日期">
              </el-date-picker>
              <el-button id="ab470c69" @click="clickab470c69()" daltag="manual">
                {{liveButtonName}}
              </el-button>
            </div>
            <vueshowdown id="a370c20a" :markdown="mkd_a370c20a">
            </vueshowdown>
          </div>
        </el-tab-pane>
        <el-tab-pane label="测试">
          <div id="bea2c9e7" daltag="manual">
            <div id="a436579f">
              {{unitInfo.name}}
            </div>
            <div id="e3be8cf9">
              <div id="a35b12ea">
                日志名称
              </div>
              <div id="a6c81fca">
                日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容日志内容
              </div>
            </div>
            <el-table id="ae8be759" :data="tbd_ae8be759" stripe="" border="">
              <el-table-column prop="da0669aa" label="时间">
              </el-table-column>
              <el-table-column prop="a8b2ab49" label="PUName">
              </el-table-column>
              <el-table-column prop="ba3f5561" label="版本号">
              </el-table-column>
              <el-table-column prop="abc5f105" label="启动时间">
              </el-table-column>
              <el-table-column prop="a08a79a2" label="结束时间">
              </el-table-column>
              <el-table-column prop="a073cd9f" label="部署耗时">
              </el-table-column>
              <el-table-column prop="ec094f2d" label="当前状态">
              </el-table-column>
              <el-table-column prop="ca4a10bd" label="部署日志内容">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="版本">
          <div id="a5146794" daltag="manual">
            <div id="aefa68f9">
              {{unitInfo.name}}
            </div>
            <vueshowdown id="a65aa84a" :markdown="mkd_a65aa84a">
            </vueshowdown>
          </div>
        </el-tab-pane>
        <el-tab-pane label="调用链">
          <div id="d6e46086">
            <div id="a072ded2">
              CPU名称
            </div>
            <vueshowdown id="c7845b54" :markdown="mkd_c7845b54">
            </vueshowdown>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import echarts from "echarts";
import dayjs from "dayjs";
import * as lodash from  "lodash";
import {
  // SYS_PUINFO_V0X0X1_GetAllUnitsDependent,
  SYS_PUINFO_V0X0X1_GetAllUnitsInfo,
  // R17771874636_LOGCENTER_V0X0X3_GetLog,
  R17771874636_LOGCENTER_V0X0X3_GetLogByUnitName,
} from "@/api/dalapi.js";
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    rdg_a7a9b839: "",
    op_ad2b21f3: [],
    val_ad2b21f3: "全部作者",
    tbd_acdd4fe8:[],
    tbd_c0ff828b: [],
    mkd_c5acd1f4:'',
    tbd_c9a1e59f: [],
    drp_bc1c3e3a: [],
    mkd_a370c20a:'',
    mkd_e3be8cf9:'',
    tbd_ae8be759: [],
    mkd_a65aa84a:'',
    mkd_c7845b54:'',
      //在此注释下方添加自定义变量
    mkd_a3d3431c:'',
    unitInfo: {
        puname: "--",
        name: "--",
        auther: "--",
        team: "--",
        onlineDate: "--",
        onlineTime: "--",
        onlineDuration: "--",
        restartCount: "--",
        callCount: "--",
        warnCount: "--",
        errorCount: "--",
        cpu: "--",
        memory: "--",
        instance: "--",
      },
    deployLog: [],
    pagesize:20,
    currentPU:"",
    tbd_logList:[],
    livePULogsFlg:false,
    liveButtonName:"开启实时刷新",
    allPu: {}
    }
  },
  async mounted() {
    var chartDom = document.getElementById("b847e8ff");
    this.chart = echarts.init(chartDom);
    let that = this;
    this.chart.on("restore", that.resetTotalView);
    this.chart.on("click", that.unitClicked);
    this.freshUnitsInfo();
    setInterval(this.freshUnitsInfo, 10*1000);
    this.freshDeployLog();
    // this.freshPULog();
  },
  computed:{
  },
  watch:{
    val_ad2b21f3(){
      this.resetTotalView();
    }
  },
  methods:{
    async clickab470c69() {
      console.log("drp_bc1c3e3a",this.drp_bfc1c3e3a);
      if(this.livePULogsFlg){
        this.livePULogsFlg = false,
        this.liveButtonName = "开启实时刷新"
      }else{
        this.livePULogsFlg = true,
        this.liveButtonName = "停止实时刷新"
        this.livePULogs()
      }
    },
    //在此注释下方添加自定义函数或事件
    freshUnitsInfo(){
      SYS_PUINFO_V0X0X1_GetAllUnitsInfo().then((puDep) => {
        var data = puDep.content;
        let first_load = (Object.keys(this.allPu).length === 0);
        if(!lodash.isEqual(this.allPu, data)){
          this.allPu = data;
          let dic_user = {};
          let author_list = [{value:"全部作者",label:"全部作者"}];
          for(let u in data){
            let user_name = u.split("-")[0];
            if(!(user_name in dic_user)){
              dic_user[user_name] = user_name;
              author_list.push({value:user_name,label:user_name})
              this.op_ad2b21f3.push({value:user_name,label:user_name})
            }
          }
          if(!lodash.isEqual(author_list, this.op_ad2b21f3))
            this.op_ad2b21f3 = author_list;
          if(first_load)
            this.resetTotalView();
        }
      });
    },
    resetTotalView() {
      let data = this.allPu;
      let totalIns = {};
      totalIns["name"] = this.val_ad2b21f3;
      if(this.val_ad2b21f3 === "全部作者")
        totalIns["author"] = "--";
      else
        totalIns["author"] = this.val_ad2b21f3;
      totalIns["num"] = 0;
      totalIns["cpu"] = 0;
      totalIns["memory"] = 0;
      totalIns["restartCount"] = 0;
      Array.prototype.forEach.call(Object.keys(data), (item) => {
        var temp = data[item];
        let item_name = item.split("-")[0];
        if(this.val_ad2b21f3 !== "全部作者" && this.val_ad2b21f3 !== item_name)
          return;
        for (let ins in temp["instances"]) {
          totalIns["num"]++;
          totalIns["cpu"] += temp["instances"][ins].resource.requests.cpu;
          let indMi = temp["instances"][ins].resource.requests.memory.indexOf(
            "Mi"
          );
          let indGi = temp["instances"][ins].resource.requests.memory.indexOf(
            "Gi"
          );
          if (indMi > 0) {
            let num = temp["instances"][ins].resource.requests.memory.substr(
              0,
              indMi
            );
            num = parseInt(num);
            totalIns["memory"] += num;
          } else if (indGi > 0) {
            let num = temp["instances"][ins].resource.requests.memory.substr(
              0,
              indGi
            );
            num = parseInt(num);
            totalIns["memory"] += num * 1024;
          }
          if (!("startTime" in totalIns)) {
            totalIns["startTime"] = dayjs(temp["instances"][ins].startTime);
          } else {
            totalIns["startTime"] = Math.min(
              totalIns["startTime"],
              dayjs(temp["instances"][ins].startTime)
            );
          }
          totalIns["restartCount"] += temp["instances"][ins].restartCount;
        }
      });
      let onlinedt = dayjs(totalIns.startTime)
      this.unitInfo = {
        puname: "--",
        name: totalIns["name"],
        author: totalIns["author"],
        team: "dev",
        onlineDate: onlinedt.format("YYYY-MM-DD"),
        onlineTime: onlinedt.format("HH:mm:ss"),
        onlineDuration: dayjs().diff(dayjs(totalIns.startTime), "day") + "天",
        restartCount: totalIns.restartCount,
        // callCount: parseInt(Math.random() * 50),
        // warnCount: parseInt(Math.random() * 50),
        // errorCount: parseInt(Math.random() * 50),
        callCount: "--",
        warnCount: "--",
        errorCount: "--",
        cpu: totalIns.cpu,
        memory: totalIns.memory,
        instance: totalIns.num,
      };
      this.updateEchart();
    },
    unitClicked(params){
      //link
      if(params.data.name === undefined) return;
      this.currentPU = params.data.name.toLowerCase()
      // this.freshPULog()
      if (!params.data.name || params.data.name === "网关") return;
      var name = params.data.name, data = {};
      data = this.allPu[name];
      // if (data["instances"].length === 0) return;
      // let ins0 = data['instances'][0]
      let totalIns = {};
      totalIns["num"] = 0;
      totalIns["cpu"] = 0;
      totalIns["memory"] = 0;
      totalIns["restartCount"] = 0;
      totalIns["author"] = name.split("-")[0];
      let lastT = name.lastIndexOf("-");
      totalIns["name"] = name.substring(0,lastT);
      for (let ins in data["instances"]) {
        totalIns["num"]++;
        totalIns["cpu"] += data["instances"][ins].resource.requests.cpu;
        let indMi = data["instances"][ins].resource.requests.memory.indexOf(
          "Mi"
        );
        let indGi = data["instances"][ins].resource.requests.memory.indexOf(
          "Gi"
        );
        if (indMi > 0) {
          let num = data["instances"][ins].resource.requests.memory.substr(
            0,
            indMi
          );
          num = parseInt(num);
          totalIns["memory"] += num;
        } else if (indGi > 0) {
          let num = data["instances"][ins].resource.requests.memory.substr(
            0,
            indGi
          );
          num = parseInt(num);
          totalIns["memory"] += num * 1024;
        }
        if (!("startTime" in totalIns)) {
          totalIns["startTime"] = dayjs(data["instances"][ins].startTime);
        } else {
          totalIns["startTime"] = Math.min(
            totalIns["startTime"],
            dayjs(data["instances"][ins].startTime)
          );
        }
        totalIns["restartCount"] += data["instances"][ins].restartCount;
      }
      let onlinedt = dayjs(totalIns.startTime)
      this.unitInfo = {
        puname: params.data.name,
        name: totalIns["name"],
        author: totalIns["author"],
        team: "dev",
        onlineDate: onlinedt.format("YYYY-MM-DD"),
        onlineTime: onlinedt.format("HH:mm:ss"),
        onlineDuration: dayjs().diff(onlinedt, "day") + "天",
        restartCount: totalIns.restartCount,
        // callCount: parseInt(Math.random() * 50),
        // warnCount: parseInt(Math.random() * 50),
        // errorCount: parseInt(Math.random() * 50),
        callCount: "--",
        warnCount: "--",
        errorCount: "--",
        cpu: totalIns.cpu,
        memory: totalIns.memory,
        instance: totalIns.num,
      };
      var sysinfo = data.sysinfo,
        arr = [];
      Array.prototype.forEach.call(Object.keys(sysinfo), (pname) => {
        var examples = sysinfo[pname].example.replace("\n", "");
        if (examples && examples !== "{}") {
          try {
            examples = JSON.parse(sysinfo[pname].example.replace("\n", ""));
            var params = Object.keys(examples).join(",");
            arr.push({
              a49924ce: pname,
              d7a4592e: sysinfo[pname].accode,
              a899d64f: sysinfo[pname].desc,
              a2309759: params,
              b7c39e67: sysinfo[pname].example.replace("\n", ""),
              a4a042e0: "复制"
            });
          } catch (e) {
            console.log(e, "e");
          }
        }
      });
      this.tbd_c0ff828b = arr;
    },
    dealPuData(data) {
      var XINTERVAL = 10;
      var YINTERVAL = 100;
      var layerColor = [
        "blue",
        "DeepSkyBlue",
        "Aqua",
        "Teal",
        "Auqamarin",
        "SeaGreen",
        "Lime",
        "DarkGreen",
        "Yellow",
        "olive",
      ];
      var keys = Object.keys(data),
        result = [{ name: "网关", x: 0, y: 0 }],
        links = [],
        maxLayer = 0;
      var layerXObj = {};
      //cal max layer
      for (let i = 0; i < keys.length; i++) {
        let layer = 1;
        let item = keys[i];
        if (data[item].dependent) {
          layer = data[item].dependent.layer;
          if (layer > 50) layer = 100;
        } else layer = 1;
        if (!(layer in layerXObj)) {
          layerXObj[layer] = {};
          layerXObj[layer]["count"] = 1;
          layerXObj[layer]["startp"] = 0;
          layerXObj[layer]["curnode"] = 0;
        } else {
          layerXObj[layer]["count"] += 1;
          layerXObj[layer]["startp"] =
            0 - ((layerXObj[layer]["count"] - 1) * XINTERVAL) / 2;
        }
        if (layer < 50) maxLayer = Math.max(layer, maxLayer);
      }
      //print
      maxLayer += 1;
      for (let i = 0; i < keys.length; i++) {
        let layer = 1;
        let item = keys[i];
        if (data[item].dependent) {
          layer = data[item].dependent.layer;
          if (layer > 50) layer = 100;
        } else layer = 1;
        let nodeColor;
        let yPos = maxLayer * YINTERVAL;
        let xPos =
          layerXObj[layer]["startp"] + layerXObj[layer]["curnode"] * XINTERVAL;
        layerXObj[layer]["curnode"] += 1;
        if (layer < 50) {
          nodeColor = layerColor[layer - 1];
          yPos = layer * YINTERVAL;
        } else {
          nodeColor = "red";
        }
        let user_name = item.split("-")[0];
        if(this.val_ad2b21f3 !== "全部作者" && this.val_ad2b21f3 !== user_name){
          result.push({
            name: item,
            x: xPos,
            y: yPos,
            symbol: "none",
            itemStyle: {
              color: nodeColor,
            },
          });
        } else {
          result.push({
            name: item,
            x: xPos,
            y: yPos,
            itemStyle: {
              color: nodeColor,
            },
          });
        }
        if (layer === 1) {
          links.push({
            source: "网关",
            target: item,
          });
        }
        if (
          data[item].dependent &&
          data[item].dependent.leaf &&
          data[item].dependent.leaf.length
        ) {
          data[item].dependent.leaf.forEach((children) => {
            var str = (
              children.author +
              "-" +
              children.unit_type +
              "-" +
              children.unit +
              "-" +
              children.version
            )
              .replace(/\./g, "X")
              .toUpperCase();
            links.push({
              source: item,
              target: str,
            });
          });
        }
      }
      return {
        result,
        links,
      };
    },
    updateEchart() {
      var option;
      var temp = this.dealPuData(this.allPu);
      this.chart.showLoading();
      var myChart = this.chart;
      myChart.hideLoading();
      myChart.setOption(
        (option = {
          tooltip: {},
          title: {
            text: "PU拓扑图", //标题文本内容
          },
          toolbox: {
            //可视化的工具箱
            show: true,
            feature: {
              restore: {
                //重置
                show: true,
              },
            },
          },
          animationDurationUpdate: 1500,
          animationEasingUpdate: "quinticInOut",
          series: [
            {
              type: "graph",
              layout: "none",
              symbolSize: 3,
              roam: true,
              zoom: 15,
              label: {
                show: true,
                rotate: -90,
                position: "right",
              },
              edgeSymbol: ["circle", "arrow"],
              edgeSymbolSize: [4, 10],
              edgeLabel: {
                fontSize: 20,
              },
              data: temp.result,
              links: temp.links,
              lineStyle: {
                opacity: 0.2,
                width: 1,
                curveness: 0,
              },
            },
          ],
        })
      );
      // });
      option && this.chart.setOption(option);
    },
    async freshDeployLog(pnum = 0) {
      // 刷新部署的日志
      let res = await this.getPULogs("sys-pu-unitcenter",pnum)

      this.deployLog = []
      for(let i = 0 ;i < res.length;i++){
        this.deployLog.push({
          logtime:res[i]._source["@timestamp"],
          logmessage:res[i]._source["message"]
        })
      }

    },
    async freshPULog(pnum = 0) {
      let res = []
      // 刷新PU的日志
      if(this.drp_bc1c3e3a.length > 0){
        res = await this.getPULogs(this.currentPU,pnum,this.drp_bc1c3e3a[0],this.drp_bc1c3e3a[1])
      }else{
        res = await this.getPULogs(this.currentPU,pnum)
      }
      this.tbd_logList = []
      for(let i = 0 ;i < res.length;i++){
        this.tbd_logList.push({
          logtime:res[i]._source["@timestamp"],
          puname:res[i]._source.agent.name,
          logmessage:res[i]._source["message"]
        })
      }
    },
    async getPULogs(puname,pnum = 0,timestart='',timeend='',sortkey="@timestamp"){
      let dicInput = {
        unitname: puname,
        pnum: pnum,
        sortkey: sortkey,
        records: this.pagesize,
        desc:1
      }
      if(timestart!==""){
        dicInput.starttime = timestart
      }
      if(timeend!==""){
        dicInput.endtime = timeend
      }
      console.log("getPULogs",dicInput);
      let res = await R17771874636_LOGCENTER_V0X0X3_GetLogByUnitName(dicInput);
      if (res.retCode == 0) {
        res = res.content.logsinfo;
      }
      console.log("R17771874636_LOGCENTER_V0X0X3_GetLogByUnitName", res);
      return res
    },
    async livePULogs(){
      if(this.livePULogsFlg){
        // this.freshPULog()
        setTimeout(this.livePULogs, 1000);
      }
    },
    async clicklogSure(){
      console.log(this.drp_bc1c3e3a,this.currentPU);
      // this.freshPULog()
    },
    copyexample(data) {
      var aux = document.createElement("input");
      let parm = JSON.parse(data.b7c39e67);
      let content = "http://dev.daline.com.cn:9090/"+this.unitInfo.puname+"/";
      content += "pu?fidx="+data.a49924ce+"&type=QueryData&";
      for(let key in parm){
        content += key+"="+parm[key]+"&";
      }
      content = content.substring(0,content.length-1);
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({message:"复制成功",type:"success"});
    }
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#bf9b207a{
  width:1589px;
  position:relative;
  padding-left:30px;
  padding-top:98px;
  padding-bottom:106px;
}
#a7a9b839{
  width:567px;
  padding-left:568px;
}
#a7a9b839{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#b8a1b817{
  width:173px;
  display:inline-block;
  vertical-align: middle;
}
#b8a1b817{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#b8a1b817 .el-radio-button__inner{
  width:173px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#fb556e8c{
  width:172px;
  margin-left:25px;
  display:inline-block;
  vertical-align: middle;
}
#fb556e8c{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#fb556e8c .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#f98f2574{
  width:172px;
  margin-left:25px;
  display:inline-block;
  vertical-align: middle;
}
#f98f2574{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#f98f2574 .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#bf9b207a .el-select{
  width:158px;
  margin-top:60px;
  margin-left:1431px;
}
#ac3e0d55{
  margin-top:60px;
}
#aa922663{
  width:937px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a6363036{
  width:937px;
  position:relative;
}
#a6363036{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:20px 20px 20px 20px;
}
#b847e8ff{
  width:899px;
  height:1097px;
  padding-left:11px;
  padding-top:8px;
  padding-bottom:9px;
}
#b847e8ff{
  vertical-align:middle;
}
#acdd4fe8{
  width:937px;
  margin-top:30px;
  margin-left:0px;
}
#acdd4fe8{
  display:inline-block;
  vertical-align: middle;
}
#a2bfef83{
  width:622px;
  margin-left:30px;
  padding-bottom:517px;
  display:inline-block;
  vertical-align: middle;
}
#a2bfef83{
  vertical-align: top;
}
#a2bfef83 /deep/ .el-tabs__item{
  font-size:13px;
  font-family:PingFang SC;
}
#a5a1de4b{
  width:621px;
}
#a047632e{
}
#a047632e{
  text-align:center;
  color:#1c1c1c;
  font-size:28px;
  font-family:PingFang SC;
}
#aa1707bd{
  margin-top:20px;
  padding-left:2px;
}
#aedf3d03{
  width:193px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#aedf3d03{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#b1f0c46e{
  padding-top:25px;
}
#b1f0c46e{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#acc0763b{
  margin-top:7px;
}
#acc0763b{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#afabbdd7{
  margin-top:4px;
  padding-bottom:4px;
}
#afabbdd7{
  text-align:center;
  color:#141620;
  font-size:14px;
  font-family:PingFang SC;
}
#c8251ee3{
  width:193px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#c8251ee3{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a1d4393a{
  padding-top:25px;
}
#a1d4393a{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#a9506b35{
  margin-top:10px;
  padding-bottom:25px;
}
#a9506b35{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#e1c4c4c1{
  width:193px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#e1c4c4c1{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#af24364a{
  padding-top:25px;
}
#af24364a{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#aac5813d{
  margin-top:10px;
  padding-bottom:25px;
}
#aac5813d{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#ab70c0ce{
  margin-top:20px;
  padding-left:1px;
}
#ad6d578a{
  width:193px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#ad6d578a{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a24b1238{
  padding-top:25px;
}
#a24b1238{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#aecdc2ab{
  margin-top:10px;
  padding-bottom:25px;
}
#aecdc2ab{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#a14f17c2{
  width:193px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#a14f17c2{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a8ec4889{
  padding-top:25px;
}
#a8ec4889{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#a5cf08db{
  margin-top:10px;
  padding-bottom:25px;
}
#a5cf08db{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#cc68871c{
  width:193px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#cc68871c{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#aff43803{
  padding-top:25px;
}
#aff43803{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#ab9c6ae2{
  margin-top:10px;
  padding-bottom:25px;
}
#ab9c6ae2{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#abab9c00{
  margin-top:20px;
  padding-left:3px;
}
#a626c45d{
  width:193px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a626c45d{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#c05e4b0e{
  padding-top:25px;
}
#c05e4b0e{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#a9b37925{
  margin-top:10px;
  padding-bottom:25px;
}
#a9b37925{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#a0be6c39{
  width:193px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#a0be6c39{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a500d403{
  padding-top:25px;
}
#a500d403{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#a9816887{
  margin-top:10px;
  padding-bottom:25px;
}
#a9816887{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#aa110879{
  width:193px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#aa110879{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a56fd943{
  padding-top:25px;
}
#a56fd943{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#c1c3fac5{
  margin-top:10px;
  padding-bottom:25px;
}
#c1c3fac5{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#e0b12218{
  margin-top:20px;
  padding-left:3px;
}
#a7f44db3{
  width:193px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a7f44db3{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#aba11f56{
  padding-top:25px;
}
#aba11f56{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#a9987c12{
  margin-top:10px;
  padding-bottom:25px;
}
#a9987c12{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#ded9b94a{
  width:193px;
  position:relative;
  margin-left:20px;
  display:inline-block;
  vertical-align: middle;
}
#ded9b94a{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#afbb932a{
  padding-top:25px;
}
#afbb932a{
  text-align:center;
  color:#141620;
  font-size:15px;
  font-family:PingFang SC;
}
#a629ed8f{
  margin-top:10px;
  padding-bottom:25px;
}
#a629ed8f{
  text-align:center;
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
}
#c0ff828b{
  width:621px;
  margin-top:56px;
}
#c0ff828b{
  display:inline-block;
  vertical-align: middle;
}
#aa87d09c{
  width:621px;
}
#b6f8af84{
}
#b6f8af84{
  text-align:center;
  color:#1c1c1c;
  font-size:28px;
  font-family:PingFang SC;
}
#c5acd1f4{
  width:620px;
  height:352px;
  margin-top:30px;
  margin-left:1px;
}
#c5acd1f4{
  background-color:rgb(255,255,255,1);
  border-radius:6px 6px 6px 6px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.16);
}
#c9a1e59f{
  width:621px;
  margin-top:60px;
}
#c9a1e59f{
  display:inline-block;
  vertical-align: middle;
}
#abcc459a{
  width:621px;
}
#aca35975{
}
#aca35975{
  text-align:center;
  color:#1c1c1c;
  font-size:28px;
  font-family:PingFang SC;
}
#a47ca6d9{
  width:620px;
  position:relative;
  margin-top:30px;
  padding-left:1px;
}
#a47ca6d9 .el-date-editor{
  width:358px;
  display:inline-block;
  vertical-align: middle;
}
#a47ca6d9 .el-date-editor{
}
#ab470c69{
  width:127px;
  margin-left:135px;
  display:inline-block;
  vertical-align: middle;
}
#ab470c69{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#ab470c69{
  color:#fff;
  font-size:13px;
  font-family:PingFang SC;
  border:transparent;
}
#a370c20a{
  width:621px;
  height:352px;
  margin-top:30px;
}
#a370c20a{
  background-color:rgb(255,255,255,1);
  border-radius:6px 6px 6px 6px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.16);
}
#bea2c9e7{
  width:621px;
}
#a436579f{
}
#a436579f{
  text-align:center;
  color:#1c1c1c;
  font-size:28px;
  font-family:PingFang SC;
}
#e3be8cf9{
  width:620px;
  height:352px;
  margin-top:30px;
  margin-left:1px;
}
#e3be8cf9{
  background-color:rgb(255,255,255,1);
  border-radius:6px 6px 6px 6px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.16);
}
#ae8be759{
  width:621px;
  margin-top:60px;
}
#ae8be759{
  display:inline-block;
  vertical-align: middle;
}
#a5146794{
  width:621px;
}
#a5146794{
  background-color:rgb(255,255,255,1);
  border-radius:0px 0px 0px 0px;
}
#aefa68f9{
}
#aefa68f9{
  text-align:center;
  color:#1c1c1c;
  font-size:28px;
  font-family:PingFang SC;
}
#a65aa84a{
  width:620px;
  height:1082px;
  margin-top:30px;
  margin-left:1px;
  margin-bottom:6px;
}
#a65aa84a{
  background-color:rgb(255,255,255,1);
  border-radius:6px 6px 6px 6px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.16);
}
#d6e46086{
  width:621px;
}
#d6e46086{
  background-color:rgb(255,255,255,1);
  border-radius:0px 0px 0px 0px;
}
#a072ded2{
}
#a072ded2{
  text-align:center;
  color:#1c1c1c;
  font-size:28px;
  font-family:PingFang SC;
}
#c7845b54{
  width:620px;
  height:1082px;
  margin-top:30px;
  margin-left:1px;
  margin-bottom:6px;
}
#c7845b54{
  background-color:rgb(255,255,255,1);
  border-radius:6px 6px 6px 6px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.16);
}
/*在此注释下方添加自定义样式*/
 #a2bfef83 {
  margin-left: 28px;
  display: inline-block;
  vertical-align: top;
}
#a370c20a {
  height: 1200px;
  overflow-y: auto;
}
#c9a1e59f {
  height: 600px;
  overflow-y: auto;
}
#ab470c69{
  margin-left: 40px;
}
</style>
